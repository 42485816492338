@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0px;
  padding: 0px;
  background-color: #141414;
}

@font-face {
  font-family: quots;
  src: url("./../src/font/STOCK.TTF");
}
@font-face {
  font-family: button;
  src: url("./../src/font/Future Friends.ttf");
}
.entry-form {
  font-family: "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto; /* Allow scrolling if needed */
  background: rgb(13, 13, 13);
  -webkit-backdrop-filter: blur(10);
          backdrop-filter: blur(10);
  background-image: url("./pic/grid.png");
  background-size: 200% 200%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  animation: gradientAnimation 15s ease infinite;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.entry-form .allform_cont {
  width: 100%;
  max-width: 400px; /* Responsive max width */
  padding: 0 15px; /* Add padding to prevent content from touching screen edges */
}
.entry-form .logo_container .logo {
  width: 150px;
}
.entry-form .quotes {
  text-align: center;
  color: white;
  font-size: 12px;
  font-family: quots;
  text-transform: capitalize;
  word-wrap: break-word;
}
.entry-form .passcode_form {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  border-bottom: 2px solid #663687;
}
.entry-form .passcode_form input[type=password] {
  letter-spacing: 2.5px;
  font-weight: bolder;
  text-align: center;
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  width: 70%;
  font-size: 16px;
}
.entry-form .passcode_form button {
  font-family: button;
  color: rgba(202, 196, 196, 0.938);
  letter-spacing: 2.5px;
  background-color: #663687;
  padding: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.entry-form .passcode_form button:hover {
  background-color: #9b59b6;
}
.entry-form .join_waitlist {
  font-family: button;
  letter-spacing: 2.5px;
  display: block;
  padding: 20px;
  margin: 20px auto;
  background-color: #9b59b6;
  color: rgb(231, 227, 227);
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.entry-form .join_waitlist:hover {
  transform: scale(1.05);
}
.entry-form .join_waitlist img {
  width: 20px;
}
.entry-form .user_info_form {
  max-height: 0;
  opacity: 0;
  transform: translateY(-20px);
  transition: max-height 0.7s ease, opacity 0.7s ease, transform 0.7s ease;
}
.entry-form .user_info_form.open {
  max-height: 1000px;
  opacity: 1;
  transform: translateY(0);
}
.entry-form input[type=text],
.entry-form input[type=email] {
  letter-spacing: 2.5px;
  font-weight: bolder;
  width: 90%;
  margin: 5px;
  padding: 15px;
  border: none;
  outline: none;
  text-align: center;
  background-color: transparent;
  color: #fff;
  outline: none;
  border-bottom: 1px solid #663687;
  font-size: 16px;
}
.entry-form .gender_selection {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.entry-form .gender_selection label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e7f8f2;
}
.entry-form .gender_selection label input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #e7f8f2;
  transition: all 0.3s ease;
  cursor: pointer;
}
.entry-form .gender_selection label input[type=radio]:checked {
  background-color: #663687;
  transform: scale(1.2);
}
.entry-form .gender_selection label span {
  font-size: 18px;
  font-family: monospace;
}
.entry-form .choosecolor_para {
  color: rgb(195, 62, 212);
  text-align: center;
  font-family: monospace;
}
.entry-form .color-selection {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.entry-form .color-selection .color-box {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.entry-form .color-selection .color-box:hover {
  transform: scale(1.1);
}
.entry-form .color-selection .color-box span {
  display: block;
  text-align: center;
  color: white;
  font-size: 20px;
  line-height: 50px;
}
.entry-form .submit-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #663687;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: button;
  letter-spacing: 2.5px;
}
.entry-form .submit-btn:hover {
  background-color: #9b59b6;
  transform: scale(1.05);
}

.error-message {
  background-color: #ff4f4f;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
}

.passcode_message {
  background-color: #66e72a;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .entry-form {
    height: 100dvh;
  }
}
.Home {
  position: relative;
  height: 100vh;
  background-color: #141414;
}
.Home .Home_Background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
}
.Home .Home_Content {
  position: relative;
  z-index: 1;
  color: white;
}
.Home .F_home_intro {
  display: grid;
  height: 100vh;
  overflow-y: scroll;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* For WebKit-based browsers */
  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #b24ae2 #121212;
  grid-template-columns: 50% 50%;
}
.Home .F_home_intro::-webkit-scrollbar {
  width: 12px;
}
.Home .F_home_intro::-webkit-scrollbar-track {
  background: #121212;
}
.Home .F_home_intro::-webkit-scrollbar-thumb {
  background-color: #be4ae2;
  border-radius: 10px;
  border: 3px solid #121212;
}
.Home .F_home_intro .f_dv {
  border-left: 1px solid white;
  height: 350vh;
  background-color: #020202;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8901960784), rgba(0, 0, 0, 0.8901960784)), url("./pic/noise_giphy.gif");
  background-position: 0 0, 50% 50%;
  background-size: auto, 400px;
}
.Home .F_home_intro .Carouselsec {
  background: rgb(0, 0, 0);
  position: sticky;
  top: 0;
  height: 100vh;
}
.Home .F_home_intro .Carouselsec .containe_container {
  display: grid;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.Home .F_home_intro .Carouselsec .containe_container .Carousel_cont {
  align-self: center;
}
.Home .F_home_intro .Carouselsec .containe_container .Carousel_cont img {
  height: 100%;
}

@media (max-width: 900px) {
  body {
    background-color: black;
  }
  .nav-container {
    padding: 10px;
    color: white;
  }
  .Titleboeard {
    max-height: 354px;
  }
  .Home {
    height: auto;
    background-color: black;
  }
  .Home .Home_Content {
    height: auto;
  }
  .Home .Home_Content .F_home_intro {
    overflow: hidden;
    height: 100%;
    display: block;
  }
  .Home .Home_Content .F_home_intro .Carouselsec {
    height: auto;
    position: relative;
    margin-bottom: 0px;
  }
  .Home .Home_Content .F_home_intro .f_dv {
    margin-top: 0px;
    border-left: 1px solid white;
    height: auto;
    background-color: #020202;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8901960784), rgba(0, 0, 0, 0.8901960784)), url(http://localhost:3000/static/media/noise_giphy.6e14996….gif);
    background-position: 0 0, 50% 50%;
    background-size: auto, 400px;
  }
  .Home .Home_Content .F_home_intro .f_dv .New_arrivals_products_cont {
    display: flex;
    overflow-x: auto;
    height: 100%;
    scrollbar-color: transparent;
    scrollbar-width: thin;
  }
  .Home .Home_Content .F_home_intro .f_dv .New_arrivals_products_cont::-webkit-scrollbar {
    height: 2px;
    width: 2px;
    background-color: transparent;
  }
  .Home .Home_Content .F_home_intro .f_dv .New_arrivals_products_cont::-webkit-scrollbar-track {
    background: transparent;
  }
  .Home .Home_Content .F_home_intro .f_dv .New_arrivals_products_cont::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .Home .Home_Content .F_home_intro .f_dv .New_arrivals_products_cont::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  .Home .Home_Content .F_home_intro .f_dv .New_arrivals_products_cont .nwr_prod {
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(43, 40, 40, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.753);
    width: 300px;
    padding: 10px;
    display: grid;
    grid-template-rows: minmax(0, 75%) auto;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
  }
}
@media (max-width: 400px) {
  .Titleboeard {
    max-height: 354px !important;
  }
  .Titleboeard .ttl {
    font-size: 10px !important;
  }
}
@font-face {
  font-family: "outline";
  src: url("./font/Future Friends.ttf");
}
.Titleboeard {
  overflow-x: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.219);
  background-color: rgb(50, 16, 50);
  padding: 10px;
}
.Titleboeard span {
  text-align: center;
  display: block;
  width: 100%;
  font-family: "outline";
  letter-spacing: 1rem;
  padding: 2px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  animation: moveRightToLeft 15s linear infinite;
}
@media (max-width: 768px) {
  .Titleboeard span {
    letter-spacing: 0.5rem;
  }
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.mySwiper {
  background-color: rgba(0, 0, 0, 0.295);
  /* Slightly transparent black background */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 10px;
  /* Rounded corners */
  overflow: hidden;
  max-height: 100%;
  position: relative;
  padding: 10px;
  /* Padding around the Swiper */
  z-index: 1;
  /* Ensure it's on top */
}

.slide-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.slide-image {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transition: transform 0.3s ease-in-out;
  /* Smooth zoom effect */
}

.slide-image:hover {
  transform: scale(1.05);
  /* Slight zoom on hover */
}

.overlaytxt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  color: white;
  padding: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

.overlaytxt h2 {
  font-size: 2em;
  margin: 0 0 10px;
}

.overlaytxt p {
  font-size: 1.2em;
  margin: 0;
}

.mySwiper .swiper-pagination-bullet-active {
  background: #b300ff;
  /* Change active pagination bullet color */
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: #b300ff;
  /* Change navigation button color */
}

.mySwiper .swiper-button-next:hover,
.mySwiper .swiper-button-prev:hover {
  color: #ff007a;
  /* Change navigation button hover color */
}

/* App.css */
/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/* Animation class for the slide-in effect */
.slide-in {
  animation: slideInFromRight 1s ease-out forwards;
}

/* Main container styles */
.intro-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  /* Aligns content to the right */
}

/* Wrapper for the content */
.content-wrapper {
  width: 40%;
  /* Adjust width as needed */
}

/* Styles for the Carousel container */
.Carousel_cont {
  position: relative;
  width: 100%;
  margin: 25px auto;
  height: 400px;
  padding: 0px !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  border-radius: 0;
  /* No border radius by default */
}

/* Remove the background border radius for larger screens */
@media (max-width: 500px) {
  .Carousel_cont {
    border-radius: 0px;
    /* Ensure no border radius for small screens */
  }
}
/* Styling for the background image */
.Carousel_cont .bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  z-index: -1;
  /* Behind the content */
}

/* Styling for the Swiper component */
.Carousel_cont .mySwiper {
  width: 100%;
  height: 100%;
  z-index: 1;
  /* On top of the background */
}

/* Faint overlay with blur effect */
.Carousel_cont::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  /* Blur effect */
  pointer-events: none;
  transform: scale(1.5);
  transition: transform 0.5s;
}

.Carousel_cont:hover::before {
  transform: scale(1);
  /* Animate overlay to cover the content */
}

@media (max-width: 500px) {
  .Carousel_cont {
    width: 100%;
    height: 400px;
    border-radius: 0px;
    /* No border radius for small screens */
  }
  .mySwiper {
    height: auto;
    /* Adjust Swiper height for smaller screens */
    border-radius: 0px;
  }
}
@font-face {
  font-family: INTRO;
  src: url("./font/Geist_Mono/GeistMono-VariableFont_wght.ttf");
}
.TypingText_container {
  text-align: center;
  border-left: 5px solid rgb(161, 40, 231);
  padding: 15px;
  line-height: 1.6rem;
  display: block;
  margin: 10px auto;
  position: relative;
  display: block;
  font-family: "Courier New", Courier, monospace;
  color: white;
  text-transform: uppercase;
  text-shadow: 1px 1px #ff0000, -1px -1px #00ff00;
  overflow: hidden;
}

.TypingText {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: inline-block;
  animation: glitch-animation 2s infinite;
}

.TypingText::before,
.TypingText::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
          clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
}

.TypingText::before {
  left: 2px;
  text-shadow: -2px 0 #ff0000;
  animation: glitch-1 1.5s infinite linear;
}

.TypingText::after {
  left: -2px;
  text-shadow: -2px 0 #00ff00;
  animation: glitch-2 1.5s infinite linear;
}

@keyframes glitch-animation {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-1px, 1px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(1px, -1px);
  }
  80% {
    transform: translate(2px, 2px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes glitch-1 {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
            clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
  }
  50% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0 60%);
            clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0 60%);
  }
  100% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0 100%);
            clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0 100%);
  }
}
@keyframes glitch-2 {
  0% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 40%, 0 40%);
            clip-path: polygon(0 20%, 100% 20%, 100% 40%, 0 40%);
  }
  50% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 80%, 0 80%);
            clip-path: polygon(0 60%, 100% 60%, 100% 80%, 0 80%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
            clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
  }
}
.new_arrivals_cont h1 {
  display: block;
  text-align: center;
  width: 50%;
  margin: 40px auto;
  border: 1px solid rgb(50, 23, 75);
  background-color: rgba(128, 0, 128, 0.363);
  -webkit-backdrop-filter: blur(5ox);
          backdrop-filter: blur(5ox);
  position: relative;
  font-family: "Courier New", Courier, monospace;
  color: white;
  text-transform: uppercase;
  text-shadow: 1px 1px #ff0000, -1px -1px #00ff00;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 7px -7px 5px 0px rgba(173, 40, 173, 0.36);
}
.new_arrivals_cont .New_arrivals_products_cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two equal columns */
  /* Add spacing between grid items */
  /* Responsive Grid */
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod:nth-child(odd) {
  border-right: 1px solid rgba(255, 255, 255, 0.753);
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod {
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.753);
  border-top: 1px solid rgba(255, 255, 255, 0.753);
  display: grid;
  grid-template-rows: minmax(0, 75%) auto;
  max-width: 100%;
  height: 400px;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod div {
  align-self: center;
  height: 100%;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod div img {
  display: block;
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod .details .line {
  width: 60%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.212);
  margin: 0 auto;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod .details .p-5 {
  padding: 20px;
  text-align: center;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod .details .p-5 p {
  margin: 5px 0;
  text-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  line-height: 1.5;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod .details .p-5 p.text-xs {
  font-size: 12px;
}
.new_arrivals_cont .New_arrivals_products_cont .nwr_prod .details .p-5 p.Price {
  margin-top: 10px;
  border: 1px solid #0d0b13;
  background-color: #8e1ab1;
  color: rgb(231, 224, 233);
  font-weight: bold;
  padding: 5px;
}
@media (max-width: 768px) {
  .new_arrivals_cont .New_arrivals_products_cont {
    grid-template-columns: 1fr;
    /* Single column on smaller screens */
  }
}

/* CSS */
.Price {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.Price:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

.overlay_button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.247);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between buttons */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.overlay_button button:nth-child(odd) {
  border: 1px solid rgb(225, 0, 255);
  padding: 8px 25px;
}
.overlay_button button:nth-child(even) {
  border: 1px solid rgb(69, 12, 77);
  padding: 8px 25px;
}

@media (min-width: 768px) {
  .Price {
    padding: 0.25em 0.75em;
  }
}
.nwr_prod:hover .overlay_button {
  opacity: 1;
}

.Navmin_cont {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 20px 50px;
  color: white;
  font-family: "Arial", sans-serif;
  font-size: 16px;
}
.Navmin_cont .logo {
  font-weight: bold;
  font-size: 24px;
  text-align: left;
}
.Navmin_cont .menu,
.Navmin_cont .actions {
  display: flex;
  align-items: center;
  gap: 25px;
}
.Navmin_cont .menu {
  text-transform: uppercase;
}
.Navmin_cont .menu .relative {
  position: relative;
  cursor: pointer;
}
.Navmin_cont .menu .relative .drop {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(168, 41, 218);
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(197, 6, 223, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 150px;
  z-index: 10;
  animation: fadeIn 0.3s ease-in-out;
}
.Navmin_cont .menu .relative .drop p {
  margin: 0;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}
.Navmin_cont .menu .relative .drop p:hover {
  border-bottom: 1px solid rgb(211, 22, 201);
  color: white;
}
.Navmin_cont .actions {
  justify-content: flex-end;
}
.Navmin_cont .actions div {
  cursor: pointer;
}
.Navmin_cont .actions div:hover {
  text-decoration: underline;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.Navmin_cont .search-bar {
  grid-column: 1/-1;
  text-align: center;
  overflow: hidden;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navmin_cont .search-bar .search-input {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.192);
  background-color: transparent;
  color: white;
}
.Navmin_cont .search-bar .search-input:focus {
  outline: none;
  border-color: white;
}
.Navmin_cont .search-bar.slide-down {
  height: 60px;
  animation: slideDown 0.4s ease forwards;
}
.Navmin_cont .search-bar.slide-up {
  animation: slideUp 0.4s ease forwards;
}
@keyframes slideDown {
  from {
    height: 0;
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    height: 60px;
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideUp {
  from {
    height: 60px;
    transform: translateY(0);
    opacity: 1;
  }
  to {
    height: 0;
    transform: translateY(-20px);
    opacity: 0;
  }
}

.Navmax {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.Navmax .burger_menu_cont .Burgermenu {
  width: 30px;
  height: 30px;
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: space-between;
}
.Navmax .burger_menu_cont .Burgermenu div {
  width: 100%;
  height: 1.5px;
  background-color: purple;
}
.Navmax .Logo_max {
  color: white;
  font-weight: bolder;
}
.Navmax .Otherlinks {
  display: flex;
  gap: 10px;
}

.Navmax {
  display: flex;
  position: relative;
  flex-direction: column;
  /* Stack elements vertically */
}
.Navmax .nav-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.Navmax .nav-container .burger_menu_cont {
  cursor: pointer;
}
.Navmax .nav-container .burger_menu_cont .Burgermenu {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Navmax .nav-container .burger_menu_cont .Burgermenu div {
  width: 100%;
  height: 2px;
  background-color: purple;
}
.Navmax .nav-container .Logo_max {
  color: white;
  font-weight: bolder;
}
.Navmax .nav-container .Otherlinks {
  display: flex;
  gap: 15px;
}
.Navmax .nav-container .Otherlinks span {
  cursor: pointer;
}
.Navmax .nav-container .Otherlinks span:hover {
  text-decoration: underline;
}
.Navmax .max_drop_menu {
  padding: 20px;
  gap: 15px;
  animation: fadeIn 0.3s ease-in-out;
  align-items: center;
  background: #000000;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1500;
}
.Navmax .max_drop_menu .close {
  position: absolute;
  right: 10px;
  top: 15px;
  border: 1px solid rgb(240, 234, 240);
  padding: 15px;
  padding: 13px 21px;
  border-radius: 37px;
}
.Navmax .max_drop_menu .cp {
  position: absolute;
  bottom: 5px;
  font-family: monospace;
  color: rgba(255, 255, 255, 0.445);
}
.Navmax .max_drop_menu .rs {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 28px;
  gap: 15px;
}
.Navmax .max_drop_menu p {
  cursor: pointer;
}
.Navmax .max_drop_menu p:hover {
  color: purple;
}
.Navmax .max_drop_menu .Drop_menu_brand {
  position: absolute;
  background-color: rgba(102, 39, 126, 0.8);
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 8px;
  animation: fadeIn 0.3s ease-in-out;
}
.Navmax .max_drop_menu .Drop_menu_brand p {
  margin: 5px;
  font-family: monospace;
  font-weight: bold;
}
.Navmax .max_drop_menu .Drop_menu_brand p:hover {
  color: white;
  border-bottom: 1px solid white;
}
.Navmax .search-bar {
  margin-top: 10px;
  /* Create space between nav and search bar */
  padding: 10px 20px;
  background-color: transparent;
  /* Black background for the search bar */
  display: flex;
  justify-content: center;
  animation: slideDown 0.3s ease-in-out;
}
.Navmax .search-bar .search-input {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(90, 88, 90, 0.6);
  background-color: transparent;
  color: white;
}
.Navmax .search-bar .search-input:focus {
  outline: none;
  border-color: white;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}/*# sourceMappingURL=App.css.map */