@font-face {
    font-family: quots;
    src: url("./../src/font/STOCK.TTF");
}
@font-face {
    font-family: button;
    src: url("./../src/font/Future\ Friends.ttf");
}
.entry-form {
    // border: 1px solid red;
    font-family: 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-x: hidden;
    // min-height: 100vh; /* Ensure form fits well on all screens */
    overflow-y: auto;  /* Allow scrolling if needed */
    background: rgba(13, 13, 13, 1);
    backdrop-filter: blur(10);
    background-image: url("./pic/grid.png");
    // filter: blur(5px);
    background-size: 200% 200%;
    object-fit: cover;
    object-position: center center;
    animation: gradientAnimation 15s ease infinite;

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .allform_cont {
        width: 100%;
        max-width: 400px; /* Responsive max width */
        padding: 0 15px; /* Add padding to prevent content from touching screen edges */
    }

    .logo_container .logo {
        width: 150px;
    }

    .quotes {
        text-align: center;
        color: white;
        font-size: 12px;
        font-family: quots;
        text-transform: capitalize;
        word-wrap: break-word;
    }

    .passcode_form {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        border-bottom: 2px solid #663687;

        input[type="password"] {
            letter-spacing: 2.5px;
            font-weight: bolder;
            text-align: center;
            background-color: transparent;
            color: #fff;
            border: none;
            outline: none;
            width: 70%;
            font-size: 16px;
        }
        
        button {
            font-family: button;
            color: rgba(202, 196, 196, 0.938);
            letter-spacing: 2.5px;
            background-color: #663687;
            padding: 15px;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
            // font-weight: bolder;
            &:hover {
                background-color: #9b59b6;
            }
        }
    }

    .join_waitlist {
        font-family: button;
        letter-spacing: 2.5px;
        display: block;
        padding: 20px;
        margin: 20px auto;
        background-color: #9b59b6;
        color: rgb(231, 227, 227);
        border: none;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.05);
        }

        img {
            width: 20px;
        }
    }

    .user_info_form {
        max-height: 0;
        opacity: 0;
        transform: translateY(-20px);
        transition: max-height 0.7s ease, opacity 0.7s ease, transform 0.7s ease;
    }

    .user_info_form.open {
        max-height: 1000px;
        opacity: 1;
        transform: translateY(0);
    }

    input[type="text"],
    input[type="email"] {
        letter-spacing: 2.5px;
        font-weight: bolder;
        width: 90%;
        margin: 5px;
        padding: 15px;
        border: none;
        outline: none;
        text-align: center;
        background-color: transparent;
        color: #fff;
        outline: none;
        border-bottom: 1px solid #663687;
        font-size: 16px;
    }

    .gender_selection {
        display: flex;
        justify-content: space-around;
        margin: 10px 0;

        label {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #e7f8f2;

            input[type="radio"] {
                appearance: none;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #e7f8f2;
                transition: all 0.3s ease;
                cursor: pointer;

                &:checked {
                    background-color: #663687;
                    transform: scale(1.2);
                }
            }

            span {
                font-size: 18px;
                font-family: monospace;
            }
        }
    }

    .choosecolor_para {
        color: rgb(195, 62, 212);
        text-align: center;
        font-family: monospace;
    }

    .color-selection {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        .color-box {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin: 0 10px;
            cursor: pointer;
            transition: transform 0.3s ease;
            // box-shadow: 0 0 10px rgba(32, 32, 32, 0.3);

            &:hover {
                transform: scale(1.1);
                // box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
            }

            span {
                display: block;
                text-align: center;
                color: white;
                font-size: 20px;
                line-height: 50px;
            }
        }
    }

    .submit-btn {
        display: block;
        width: 100%;
        padding: 15px;
        background-color: #663687;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        font-family: button;
        letter-spacing: 2.5px;
        &:hover {
            background-color: #9b59b6;
            transform: scale(1.05);
        }
    }
}

.error-message {
    background-color: #ff4f4f;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
}

.passcode_message{
    background-color: #66e72a;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
}
@media screen and (max-width:500px) {
    .entry-form{
        height: 100dvh;
    }
}