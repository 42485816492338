@font-face {
    font-family: INTRO;
    src: url("./font/Geist_Mono/GeistMono-VariableFont_wght.ttf");
}

// .Typintext {
// }

.TypingText_container {
    // border-bottom: 1px solid white;
    text-align: center;
    border-left: 5px solid rgb(161, 40, 231);
    padding: 15px;
    //     font-family: INTRO;
    line-height: 1.6rem;
    //     text-transform: uppercase;
    display: block;
    // width: 50%;
    margin: 10px auto;
    position: relative;
    display: block;
    font-family: "Courier New", Courier, monospace;
    // font-size: 2rem;
    color: white;
    text-transform: uppercase;
    text-shadow:
        1px 1px #ff0000,
        -1px -1px #00ff00;
    overflow: hidden;
}

.TypingText {
    position: relative;
    user-select: none;
    display: inline-block;
    animation: glitch-animation 2s infinite;
}

.TypingText::before,
.TypingText::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
}

.TypingText::before {
    left: 2px;
    text-shadow: -2px 0 #ff0000;
    animation: glitch-1 1.5s infinite linear;
}

.TypingText::after {
    left: -2px;
    text-shadow: -2px 0 #00ff00;
    animation: glitch-2 1.5s infinite linear;
}

@keyframes glitch-animation {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-1px, 1px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(1px, -1px);
    }

    80% {
        transform: translate(2px, 2px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes glitch-1 {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
    }

    50% {
        clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0 60%);
    }

    100% {
        clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0 100%);
    }
}

@keyframes glitch-2 {
    0% {
        clip-path: polygon(0 20%, 100% 20%, 100% 40%, 0 40%);
    }

    50% {
        clip-path: polygon(0 60%, 100% 60%, 100% 80%, 0 80%);
    }

    100% {
        clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
    }
}

//new arrivals

.new_arrivals_cont {
    h1 {
        display: block;
        text-align: center;
        width: 50%;
        margin: 40px auto;
        border: 1px solid rgb(50, 23, 75);
        background-color: rgba(128, 0, 128, 0.363);
        backdrop-filter: blur(5ox);
        position: relative;
        font-family: "Courier New", Courier, monospace;
        // font-size: 2rem;
        color: white;
        text-transform: uppercase;
        text-shadow:
            1px 1px #ff0000,
            -1px -1px #00ff00;
        border-radius: 15px;
        padding: 10px;
        -webkit-box-shadow: 17px -13px 5px 0px rgb(173 40 173 / 36%);
        -moz-box-shadow: 17px -13px 5px 0px rgb(173 40 173 / 36%);
        ;
        box-shadow: 7px -7px 5px 0px rgb(173 40 173 / 36%);
        ;
    }

    .New_arrivals_products_cont {
        display: grid;
        // height: 400px;

        grid-template-columns: repeat(2, 1fr);
        /* Two equal columns */
        // gap: 10px;
        /* Add spacing between grid items */

        .nwr_prod:nth-child(odd) {
            border-right: 1px solid rgba(255, 255, 255, 0.753);
        }

        .nwr_prod {
            cursor: pointer;
            position: relative;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255, 255, 255, 0.753);
            border-top: 1px solid rgba(255, 255, 255, 0.753);
            display: grid;
            grid-template-rows: minmax(0, 75%) auto;
            max-width: 100%;
            height: 400px; // hight added to each cart

            div {
                align-self: center;
                height: 100%;

                img {
                    display: block;
                    margin: 0 auto;
                    height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                }
            }

            .details {
                .line {
                    width: 60%;
                    height: 2px;
                    background-color: rgba(255, 255, 255, 0.212);
                    margin: 0 auto;
                }

                .p-5 {
                    padding: 20px;
                    text-align: center;

                    p {
                        margin: 5px 0;
                        text-wrap: break-word;
                        word-wrap: break-word;
                        word-break: break-word;
                        white-space: normal;
                        line-height: 1.5;

                        &.text-xs {
                            font-size: 12px;
                        }

                        &.Price {
                            margin-top: 10px;
                            border: 1px solid #0d0b13;
                            background-color: #8e1ab1;
                            color: rgb(231, 224, 233);
                            font-weight: bold;
                            padding: 5px;
                        }
                    }
                }
            }
        }

        /* Responsive Grid */
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            /* Single column on smaller screens */
        }
    }

}



/* CSS */
.Price {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    border: 3px solid;
    padding: 0.25em 0.5em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    &:active {
        box-shadow: 0px 0px 0px 0px;
        top: 5px;
        left: 5px;
    }
}

.overlay_button{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.247);
    backdrop-filter: blur(5px);
    
    // bottom: 10%; /* Position above the bottom */
    // left: 50%;
    top: 0;right: 0;
    bottom: 0;left: 0;
    // transform: translate(-50%, 100%); /* Start hidden below the cart */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between buttons */
    opacity: 0; /* Hidden by default */

    button:nth-child(odd){
        border: 1px solid rgb(225, 0, 255);
        padding: 8px 25px;
        
    }
    button:nth-child(even){
        border: 1px solid rgb(69, 12, 77);
        padding: 8px 25px;
    }
    transition: opacity 0.3s ease, transform 0.3s ease;
}


@media (min-width: 768px) {
    .Price {
        padding: 0.25em 0.75em;
    }
}
.nwr_prod:hover{
    .overlay_button{
        opacity: 1;
    }
}