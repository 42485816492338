.Navmin_cont {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 20px 50px;
    // background-color: black; // Black background like in the screenshot
    color: white; // White text
    font-family: 'Arial', sans-serif; // Clean sans-serif font
    font-size: 16px;

    .logo {
        font-weight: bold;
        font-size: 24px; // Larger logo text
        text-align: left;
    }

    .menu,
    .actions {
        display: flex;
        align-items: center;
        gap: 25px;
    }

    .menu {
        text-transform: uppercase; // Uppercase for menu items

        .relative {
            position: relative; // Required for dropdown positioning
            cursor: pointer;

            .drop {
                position: absolute;
                top: 100%; // Position the dropdown below the "BRAND" item
                left: 0;
                background-color: rgb(168, 41, 218); // Dropdown background
                color: rgb(255, 255, 255); // Text color for dropdown
                padding: 10px;
                border-radius: 5px;
                box-shadow: 0 4px 8px rgba(197, 6, 223, 0.2);
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 150px; // Set dropdown width
                z-index: 10; // Ensure it's above other elements
                animation: fadeIn 0.3s ease-in-out;

                p {
                    margin: 0;
                    padding: 5px;
                    font-size: 14px;
                    cursor: pointer;

                    &:hover {
                        // background-color:  ;
                        border-bottom: 1px solid rgb(211, 22, 201);
                        color: white;
                    }
                }
            }
        }
    }

    .actions {
        justify-content: flex-end;

        div {
            cursor: pointer; // Add hover effect

            &:hover {
                text-decoration: underline; // Underline on hover for better interactivity
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .search-bar {
        grid-column: 1 / -1; // Span across the full width
        text-align: center;
        overflow: hidden;
        height: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .search-input {
            width: 50%;
            padding: 10px;
            font-size: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.192);
            // border-radius: 5px;
            background-color: transparent;
            color: white;

            &:focus {
                outline: none;
                border-color: white;
            }
        }

        &.slide-down {
            height: 60px; // Set height when visible
            animation: slideDown 0.4s ease forwards;
        }

        &.slide-up {
            animation: slideUp 0.4s ease forwards;
        }
    }

    @keyframes slideDown {
        from {
            height: 0;
            transform: translateY(-20px);
            opacity: 0;
        }

        to {
            height: 60px;
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slideUp {
        from {
            height: 60px;
            transform: translateY(0);
            opacity: 1;
        }

        to {
            height: 0;
            transform: translateY(-20px);
            opacity: 0;
        }
    }
}

.Navmax {
    // border: 1px solid red;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .burger_menu_cont {
        .Burgermenu {
            width: 30px;
            height: 30px;
            display: flex;
            padding: 2px;
            flex-direction: column;
            justify-content: space-between;

            div {
                width: 100%;
                height: 1.5px;
                background-color: purple;
            }
        }
    }

    .Logo_max {
        color: white;
        font-weight: bolder;
    }

    .Otherlinks {
        display: flex;
        gap: 10px;
    }
}

.Navmax {
    display: flex;
    position: relative;
    flex-direction: column;
    /* Stack elements vertically */
    // border: 1px solid red;

    .nav-container {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .burger_menu_cont {
            cursor: pointer;

            .Burgermenu {
                width: 30px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                div {
                    width: 100%;
                    height: 2px;
                    background-color: purple;
                }
            }
        }

        .Logo_max {
            color: white;
            font-weight: bolder;
        }

        .Otherlinks {
            display: flex;
            gap: 15px;

            span {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .max_drop_menu {
        // position: absolute;
        // top: 0;

        // background-color: rgba(0, 0, 0, 0.9);
        // color: white;
        padding: 20px;
        // display: flex;
        // flex-direction: column;
        gap: 15px;
        animation: fadeIn 0.3s ease-in-out;
        ///
        align-items: center;
        background: #000000;
        bottom: 0;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        // opacity: 0;
        // pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        // transition: opacity .3s ease-in-out;
        z-index: 1500;
        .close{
            position: absolute;
            right: 10px;
            top: 15px;
            border:1px solid rgb(240, 234, 240);
            padding: 15px;
            padding: 13px 21px;
            border-radius: 37px;       
         }
         .cp{
            position: absolute;
            bottom: 5px;
            font-family: monospace;
            color: rgba(255, 255, 255, 0.445);

         }
         .rs{
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom: 28px;
            gap: 15px;
         }
        p {
            cursor: pointer;

            &:hover {
                color: purple;
            }
        }

        .Drop_menu_brand {

            position: absolute;
            background-color:rgb(102 39 126 / 80%);
            
            margin-top: 10px;
            margin-right: 10px;
            padding: 10px;
            border-radius: 8px;
            animation: fadeIn 0.3s ease-in-out;

            p {
                margin: 5px;
                font-family: monospace;
                font-weight: bold;

                &:hover {
                    color: white;
                    border-bottom: 1px solid white;
                }
            }
        }
    }

    .search-bar {
        margin-top: 10px;
        /* Create space between nav and search bar */
        padding: 10px 20px;
        background-color: transparent;
        /* Black background for the search bar */
        display: flex;
        justify-content: center;
        animation: slideDown 0.3s ease-in-out;

        .search-input {
            width: 100%;
            text-align: center;
            // border: 1px solid red;
            padding: 10px;
            font-size: 16px;
            border: none;
            border-bottom: 1px solid rgba(90, 88, 90, 0.6);
            background-color: transparent;
            color: white;

            &:focus {
                outline: none;
                border-color: white;
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}