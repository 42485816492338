.mySwiper {
    // border: 1px solid red;
    background-color: rgba(0, 0, 0, 0.295);
    /* Slightly transparent black background */
    backdrop-filter: blur(5px);
    border-radius: 10px;
    /* Rounded corners */
    overflow: hidden;
    max-height: 100%;
    // box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8); /* Shadow for depth */
    position: relative;
    padding: 10px;
    /* Padding around the Swiper */
    z-index: 1;
    /* Ensure it's on top */
}

.slide-content {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
}

.slide-image {
    position: absolute;
    top: 0;
    bottom: 0;
    // border: 4px solid yellow;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
    /* Smooth zoom effect */
}

.slide-image:hover {
    transform: scale(1.05);
    /* Slight zoom on hover */
}

.overlaytxt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    color: white;
    padding: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

.overlaytxt h2 {
    font-size: 2em;
    margin: 0 0 10px;
}

.overlaytxt p {
    font-size: 1.2em;
    margin: 0;
}

.mySwiper .swiper-pagination-bullet-active {
    background: #b300ff;
    /* Change active pagination bullet color */
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
    color: #b300ff;
    /* Change navigation button color */
}

.mySwiper .swiper-button-next:hover,
.mySwiper .swiper-button-prev:hover {
    color: #ff007a;
    /* Change navigation button hover color */
}

//
/* App.css */

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Animation class for the slide-in effect */
.slide-in {
    animation: slideInFromRight 1s ease-out forwards;
}

/* Main container styles */
.intro-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    /* Aligns content to the right */
}

/* Wrapper for the content */
.content-wrapper {
    width: 40%;
    /* Adjust width as needed */
}

/* Styles for the Carousel container */
.Carousel_cont {
    position: relative;
    width: 100%;
    margin: 25px auto;
    height: 400px;
    padding: 0px !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    border-radius: 0;
    /* No border radius by default */
}

/* Remove the background border radius for larger screens */
@media (max-width: 500px) {
    .Carousel_cont {
        border-radius: 0px;
        /* Ensure no border radius for small screens */
    }
}

/* Styling for the background image */
.Carousel_cont .bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    z-index: -1;
    /* Behind the content */
}

/* Styling for the Swiper component */
.Carousel_cont .mySwiper {
    width: 100%;
    height: 100%;
    z-index: 1;
    /* On top of the background */
}

/* Faint overlay with blur effect */
.Carousel_cont::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    backdrop-filter: blur(5px);
    /* Blur effect */
    pointer-events: none;
    transform: scale(1.5);
    transition: transform 0.5s;
}

.Carousel_cont:hover::before {
    transform: scale(1);
    /* Animate overlay to cover the content */
}

@media (max-width: 500px) {
    .Carousel_cont {
        width: 100%;
        height: 400px;
        border-radius: 0px;
        /* No border radius for small screens */
    }

    .mySwiper {
        height: auto;
        /* Adjust Swiper height for smaller screens */
        border-radius: 0px;
    }
}