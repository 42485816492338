@font-face {
    font-family: "outline";
    src: url("./font/Future\ Friends.ttf"); // Updated file name
}

.Titleboeard {
    overflow-x: hidden;
    // border-top: 1px solid white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.219);
    background-color: rgb(50 16 50);
    padding: 10px;

    span {
        text-align: center;
        display: block;
        width: 100%;
        font-family: "outline";
        letter-spacing: 1rem;
        padding: 2px;
        font-size: 18px;
        color: rgb(255, 255, 255);
        animation: moveRightToLeft 15s linear infinite;

        @media (max-width: 768px) {
            letter-spacing: 0.5rem; // Responsive adjustment
        }
    }
}

@keyframes moveRightToLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
