.Home {
    position: relative;
    height: 100vh;
    background-color: #141414;

    .Home_Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background-image: url("./pic/Contour\ Line\ \(1\).svg");
        background-size: cover;
        background-position: center;
        filter: blur(5px);
        // z-index: -1;
    }

    .Home_Content {
        position: relative;
        z-index: 1;
        color: white;
    }

    .F_home_intro {
        display: grid;
        height: 100vh;
        overflow-y: scroll;
        overflow-y: scroll;
        scroll-behavior: smooth;

        /* For WebKit-based browsers */
        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #121212;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #be4ae2;
            border-radius: 10px;
            border: 3px solid #121212;
        }

        /* For Firefox */
        scrollbar-width: thin;
        scrollbar-color: #b24ae2 #121212;
        grid-template-columns: 50% 50%;

        .f_dv {
            border-left: 1px solid white;
            height: 350vh;
            background-color: #020202;
            background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .89)), to(rgba(0, 0, 0, .89))), url("./pic/noise_giphy.gif");
            background-image: linear-gradient(180deg, #000000e3, #000000e3), url("./pic/noise_giphy.gif");
            background-position: 0 0, 50% 50%;
            background-size: auto, 400px;

        }

        .Carouselsec {
            background: rgb(0, 0, 0);
            position: sticky;
            top: 0;
            height: 100vh;

            // border: 1px solid red;
            .containe_container {
                display: grid;
                height: 100%;
                // background-color: rgba(0, 0, 0, 0.945);
                backdrop-filter: blur(5px);

                .Carousel_cont {
                    align-self: center;

                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.f_div_cont {}

//
@media (max-width: 900px) {
    body{
        background-color: black;
    }
    .nav-container{
        padding: 10px;
        color: white;
    }
    .Titleboeard{
        max-height: 354px;

    }
    .Home {
        height: auto;
        background-color: black;
        .Home_Content {
            height: auto;

            .F_home_intro {
                overflow: hidden;
                height: 100%;
                display: block;

                .Carouselsec {
                    height: auto;
                    position: relative;
                    margin-bottom: 0px;
                }

                .f_dv {
                    margin-top: 0px;
                    // overflow-: scroll !important;
                    border-left: 1px solid white;
                    height: auto;
                    background-color: #020202;
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8901960784), rgba(0, 0, 0, 0.8901960784)), url(http://localhost:3000/static/media/noise_giphy.6e14996….gif);
                    background-position: 0 0, 50% 50%;
                    background-size: auto, 400px;

                    .New_arrivals_products_cont {
                        display: flex;
                        // align-items: center;
                        overflow-x: auto;
                        height: 100%;

                        // Custom scrollbar styles for WebKit browsers
                        &::-webkit-scrollbar {
                            height: 2px;
                            width: 2px;
                            background-color: transparent;
                            // height: 10px; // Height of the scrollbar
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent; // Background of the scrollbar track
                        }

                        &::-webkit-scrollbar-thumb {
                            background: transparent; // Color of the scrollbar thumb
                            // border-radius: 5px; // Rounded corners for the thumb
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: transparent; // Color of the scrollbar thumb on hover
                        }

                        // Custom scrollbar styles for Firefox
                        scrollbar-color: transparent; // Thumb and track color
                        scrollbar-width: thin;

                        .nwr_prod {
                            cursor: pointer;
                            box-shadow: 0 10px 20px rgba(43, 40, 40, 0.1);
                            // border-radius: 15px;
                            border: 1px solid rgba(255, 255, 255, 0.753);
                            width: 300px;
                            padding: 10px;
                            display: grid;
                            grid-template-rows: minmax(0, 75%) auto;
                            // height: 100%;
                            position: relative;
                            flex-direction: column;
                            flex-shrink: 0; // Prevent shrinking
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

}
@media (max-width: 400px){
    .Titleboeard{
        max-height: 354px !important;
        .ttl{

            font-size: 10px !important;
        }
    }
}